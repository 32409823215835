

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/avocat-droit-affaires-incorporation-entreprise-prix.jpeg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost50 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Quel est le tarif d'un avocat pour l’incorporation d’une entreprise au Québec en 2021 - Soumissions Avocat"
        description="L’incorporation d’une entreprise n’est pas un processus particulièrement complexe. Cependant, incorporer une entreprise de façon optimale en mettant en place une structure solide est un tout autre jeu qui nécessite l’intervention d’experts. Cette expertise, vous la trouverez auprès d’un avocat des affaires qui œuvre dans la mise en place de LIRE PLUS"
        image={LeadImage}>
        <h1>Quel est le tarif d’un avocat pour l’incorporation d’une entreprise au Québec en 2021</h1>

					
					
						<p>L’incorporation d’une entreprise n’est pas un processus particulièrement complexe. Cependant, incorporer une entreprise de façon optimale en mettant en place une structure solide est un tout autre jeu qui nécessite l’intervention d’experts. Cette expertise, vous la trouverez auprès d’un avocat des affaires qui œuvre dans la mise en place de structures corporatives et qui s’y connait dans le monde du droit commercial.</p>
<p><StaticImage alt="avocat-droit-affaires-incorporation-entreprise-prix" src="../images/avocat-droit-affaires-incorporation-entreprise-prix.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Toutefois, quelques-uns d’entre vous que vous soyez propriétaires d’une entreprise ou non pourraient être confus par rapport à l’incorporation. Les entreprises ne sont-elles pas toutes pareilles : un propriétaire, des dirigeants et des employés travaillant au bénéfice de la compagnie? Loin de là! La structure d’une entreprise affecte grandement la façon dont elle est administrée, les droits des propriétaires en plus d’amener de nombreuses ramifications au niveau légal. Nous vous présentons donc les raisons pour lesquelles vous devriez songer à incorporer votre entreprise avec un avocat spécialisé dans le domaine!</p>
<h2><strong>Qu’est-ce que l’incorporation?</strong></h2>
<p>L’incorporation est une façon d’exploiter une entreprise en la transformant en société par actions. Se faisant, l’entité qui était autrefois la propriété d’une seule personne appartient maintenant à une collectivité d’investisseurs appelés des actionnaires. Ceux-ci investissent dans la compagnie en retour d’actions qui elles, offrent comme avantage la perception de dividendes et de certains autres droits.</p>
<p>Cela n’est pas la fin de l’histoire! En faisant incorporer une entreprise, vous transformez celle-ci en compagnie (synonyme de société par actions), ce qui a pour effet de conférer une personnalité juridique distincte à cette dernière : elle devient ce que l’on appelle une « personne morale ». La compagnie a donc ses propres droits et obligations, ce qui n’est pas le cas d’une entreprise individuelle. En effet, si vous êtes propriétaires d’une entreprise dite individuelle, sachez que vous êtes responsables de chacune des actions posées par celle-ci.</p>
<p>L’incorporation implique également la création d’un conseil d’administration qui gérera le fonctionnement de la compagnie au nom des actionnaires. Ce conseil d’administration aura des pouvoirs bien précis prévus par convention et seront redevable de comptes auprès des actionnaires. Toutefois, ces derniers sont également protégés par le voile corporatif, signifiant que leurs actions portées au nom de la compagnie ne peuvent être retenues contre eux que si elles sont de nature frauduleuse ou criminelle.</p>
<p>La société par action et l’entreprise individuelle ne sont pas les deux seules structures d’entreprise qu’il est possible de former. Pour vous faciliter la tâche, voici une brève fiche descriptive de chacune de ces structures :</p>
<p><strong>L’entreprise individuelle :</strong> Un seul individu assume la charge de propriété de toute l’entreprise. Il en est propriétaire à part entière, ce qui implique que toutes les décisions lui reviennent à part entière. Cela a l’avantage de simplifier la prise de décision et l’administration des compagnies de petite taille, mais cela constitue également un risque au niveau juridique.</p>
<p>À vrai dire, comme le propriétaire et l’entreprise ne forment qu’une seule personnalité juridique, chacun est responsable des actions de l’autre. Cela implique qu’une poursuite contre une entreprise non incorporée impliquera le propriétaire de celle-ci personnellement. Cela est d’autant plus inquiétant sachant que la même chose est vraie pour la faillite; si votre entreprise devient insolvable, vous coulerez avec celle-ci!</p>
<p>Du côté des inconvénients, il y a également le niveau financier qui joue contre l’entreprise individuelle. Comme une telle structure exige la production d’un rapport d’impôt unique (les profits de la compagnie s’ajouteront à votre rapport personnel), vous paierez plus d’impôt que si vous aviez incorporé l’entreprise.</p>
<p>Bref, l’entreprise individuelle a toutefois l’avantage d’être exclusive au niveau du partage des profits : il n’y en a pas. Vous devez donc accepter d’accepter les pertes autant que les gains si vous optez pour une telle structure. De plus, n’oubliez pas que votre responsabilité civile est engagée, alors pensez bien aux risques que vous prenez, ceux-ci pourraient vous coûter cher!</p>
<p>Finalement, votre propre mort entraînera également celle de votre entreprise, ce qui n’est pas le cas d’une société par actions. Cela compliquera également tout projet de succession que vous auriez pu avoir!</p>
<p><StaticImage alt="structure-entreprise-societe-action-compagnie-avocat." src="../images/structure-entreprise-societe-action-compagnie-avocat.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>
La société par actions : </strong>On l’appelle plus communément une compagnie, tout simplement. Tel que mentionné, la société par action est une entreprise qui s’est transformée en personne morale possédant sa propre personnalité juridique.</p>
<p>N’étant plus la propriété d’une seule personne, elle appartient désormais aux actionnaires possédant les droits que leur confèrent les actions qu’ils détiennent. Différents types d’actions entrainent différents droits, mais on retrouve toujours au moins un de trois droits : le droit aux dividendes, le droit au reliquat et le droit de vote.</p>
<p>Bien que les actionnaires soient propriétaires de l’entreprise, c’est entre les mains du conseil d’administration que sont laissés les rennes de cette dernière. Dépendamment de la taille de la compagnie, le nombre d’actionnaires et de dirigeants siégeant sur le conseil d’administration variera grandement.</p>
<p>Toutefois, chaque société par actions doit se doter d’une loi constitutive qui a pour effet de créer la compagnie elle-même, d’une charte, d’une liste de règlements et d’une convention d’actionnaires. Vous voyez donc que c’est une structure beaucoup plus exigeante que celle d’une entreprise individuelle ou d’une société en nom collectif. Nous parlerons un peu plus loin des avantages qui accompagnent une compagnie dument incorporée.</p>
<p><strong>La société en nom collectif : </strong>Ce type de structure est en fait un partenariat de personnes se regroupant de la poursuite d’un objectif commun. D’une certaine façon, il s’agit d’un compromis entre une société par actions et une entreprise individuelle, car sa constitution est simple à accomplir, mais son administration nécessite tout de même l’accomplissement de quelques formalités.</p>
<p>Un des principes fondamentaux de la société en nom collectif est la suivante : les partenaires partagent les bénéfices et les pertes financières. C’Est pourquoi la loi parle explicitement d’un « esprit de collaboration » lorsqu’elle qualifie la société en nom collectif : un pour tous et tous pour un! La répartition des profits se fait selon la proportion des parts établies dans le contrat de société. Rien n’oblige une répartition égale des parts!</p>
<p>Mais comment se forme une société en nom collectif? Par la rédaction, signature et la déclaration d’un contrat de société. Ce document est de la plus haute importance, car il établit la destination de l’entreprise (son but), la part de chaque associé dans l’entreprise et l’étendue de la responsabilité de chacun. Parlant de responsabilité, contrairement à une personne morale, la société en nom collectif ne créé pas de distinction entre la personnalité juridique des partenaires et celle de la société : chacun des partenaires engage donc personnellement sa responsabilité civile.</p>
<p>Cela emporte donc d’importantes responsabilités, car un seul partenaire peut être tenu responsable des actions de ses associés partis au contrat. Pour cette raison, la plupart des professionnels travaillant sous un contrat de société détiennent une assurance responsabilité personnelle pour couvrir ce type de dommages.</p>
<h2><strong>Les étapes à suivre pour mettre en place une structure corporative!</strong></h2>
<p>L’incorporation est une démarche administrative qui se fait en quelques étapes seulement. Toutefois, chaque étape exige de faire des choix qui ont des répercussions importantes sur l’avenir de la compagnie. C’est pourquoi il vaut mieux être assisté d’un expert légal tout au long de ce processus!</p>
<p><StaticImage alt="incorporation-federale-provinciale-etapes-structure-entreprise" src="../images/incorporation-federale-provinciale-etapes-structure-entreprise.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>
-Incorporation fédérale vs. provinciale :</strong> Cette décision n’est pas celle qui aura le plus grand impact sur la survie de votre entreprise. En effet, que vous incorporiez au fédéral ou au provincial n’a qu’une incidence minime sur le fonctionnement de l’entreprise.</p>
<p>La décision se base principalement sur le lieu où l’entreprise sera exploitée. Si vous prévoyez exploiter votre compagnie d’un océan à l’autre, il serait peut-être préférable d’opter pour une société par actions fédérale, alors qu’une incorporation provinciale est préférable si vos activités se limitent au territoire de la belle province.</p>
<p>Dans un cas comme dans l’autre, la compagnie doit être enregistrée au registre des entreprises pour pouvoir opérer légalement au Québec.
<strong>-Choix de structure : </strong>C’est ici que vous ferez un choix parmi la société par actions (la compagnie), la société en commandite et la société en nom collectif. Il vaut mieux recevoir des conseils d’experts à cette étape, puisque la structure est d’une importance sans pareil au bon fonctionnement de votre compagnie.</p>
<p><strong>-Choix et enregistrement de nom : </strong>Toute entreprise doit enregistrer son nom au Registre des entreprises du Québec. Bien que certains optent pour l’enregistrement d’une compagnie à numéro, la majorité des entrepreneurs choisissent plutôt de trouver un nom qui représente l’entreprise.</p>
<p>Ici aussi vous pourriez utiliser l’aide d’un expert, bien que ceux du domaine légal limiteront leurs conseils à l’aspect « respect des droits d’auteurs » de la situation! C’est plutôt du côté marketing que vous devriez chercher de l’aide si vous manquez d’inspiration!</p>
<p><strong> </strong></p>
<p><strong>-Production de documents : </strong>On parle ici de la déclaration initiale prévoyant les droits de chacun des actionnaires, du type d’actions disponibles, de la quantité d’actions émises et d’autres informations relatives à l’administration de la compagnie.</p>
<p>Bien que des modèles d’actions de base existent, il est souvent plus intéressant de créer des titres qui sont propres à l’entreprise. Mais prenez garde, car comme les actions donnent des droits à leurs titulaires, il est important que vous soyez assistés d’un juriste au moment de prévoir les droits des actionnaires. Sinon, vous pourriez tout aussi bien vous tirer dans le pied!</p>
<p><strong> </strong></p>
<p><strong>-Paiement des frais administratifs : </strong>En effet, l’incorporation entraîne des frais administratifs, que vous choisissiez l’incorporation provinciale ou fédérale. On parle ici d’un prix de 330$ pour compléter la démarche d’incorporation de A à Z. Toutefois, cela n’inclut pas les nombreux autres frais afférents qui accompagnent ce processus, vous vous en doutez bien!</p>
<p><strong> </strong></p>
<p><strong>-Obtention du certificat : </strong>Une fois les documents envoyés en bonne et due forme aux personnes responsables, vous recevrez un certificat d’incorporation qui confirmera l’existence de votre société par actions ainsi que ses droits entant que personne morale.</p>
<p><strong> </strong></p>
<p><strong>-Organisation finale :</strong> Une fois la structure mise en place, ce sera le moment de procéder à la paperasse interne qui elle prévoira les règlements, la composition du conseil d’administration ainsi que les droits et obligations des dirigeants.</p>
<h2><strong>Quels sont les avantages?</strong></h2>
<p>Malgré les exigences légales et administratives nécessaires à l’incorporation d’une entreprise, il y a de nombreux avantages qui accompagnent une telle structure. Vous verrez donc que d’engager un avocat pour vous aider à incorporer votre entreprise est un loin d’être une démarche accomplie en vain.</p>
<p><StaticImage alt="responsabilite-civile-dirigeant-entreprise-incorporation-avantages" src="../images/responsabilite-civile-dirigeant-entreprise-incorporation-avantages.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>
La responsabilité légale : </strong>Dans le langage juridique, on utilise le « voile corporatif » comme outil pour séparer la responsabilité légale de l’entreprise et de ses dirigeants. Tel que mentionné précédemment, les actions de l’un n’entraînent pas la responsabilité de l’autre, ce qui fait en sorte que les dirigeants sont à l’abri des poursuites qui ne les concernent pas personnellement. La seule exception est, bien sûr, la perpétration d’un acte criminel commis dans le cadre des fonctions du dirigeant au sein de l’entreprise.</p>
<p>De plus, les dirigeants de l’entreprise ne seront pas responsables des dettes de l’entreprise si celle-ci rencontrait des troubles de solvabilité. La compagnie est donc une importante protection pour les dirigeants d’une entreprise, et ce, sur plusieurs fronts!</p>
<p><strong>Les impôts : </strong>Le propriétaire d’une entreprise individuelle joint sa déclaration d’impôt à celle de son entreprise. Cela fait augmenter le revenu annuel perçu ce qui, bien évidemment, fait monter la « bracket » d’impôt.</p>
<p>En revanche, les compagnies ont leurs propres barèmes d’imposition qui sont généralement beaucoup moins élevés que ceux qui sont offerts aux particuliers percevant d’importants salaires annuels.</p>
<p>C’est donc une stratégie financière sensée autant pour les dirigeants que pour l’entreprise elle-même que de procéder à l’incorporation : c’est de l’argent de plus dans le portefeuille de tout le monde impliqué!</p>
<p><strong>Financement et partage de dividendes :</strong> Les institutions financières acceptent plus facilement de prêter de l’argent aux sociétés par actions qu’aux entreprises individuelles. La raison? Leur capacité de générer des revenus (et donc de rembourser leurs dettes) est bien plus grande que celle des propriétaires d’entreprises individuelles. De plus, les suretés que de telles compagnies sont prêtes à offrir sont bien plus intéressantes pour les banques que celles que peuvent offrir de petites entreprises.</p>
<p>De plus, un des principes de base de la société par actions est celui du partage de dividendes parmi les actionnaires de façon annuelle. Toutefois, un tel principe peut être écarté dans certains cas pour permettre à la compagnie d’accumuler de l’argent dans ses coffres. Seuls les actionnaires ayant des actions garantissant le paiement de dividendes chaque année ne seront pas écartés si facilement.</p>
<p><strong>Pérennité de l’entreprise :</strong> La beauté de la société par actions, c’est que sa survie ne dépend aucunement de celle de ses propriétaires. Comme elle est la propriété des actionnaires, elle survit par-delà la mort de toute personne détenant des titres d’action.</p>
<p>Comme les actions sont des biens appartenant à leur titulaire, ils se transmettent en héritage à toute personne visée par son propriétaire. La seule chose qui peut mettre un terme à l’existence d’une société par actions est sa dissolution.</p>
<p>Celle-ci peut être causée par une faillite, une fusion, une acquisition ou encore par une cessation volontaire de toute activité par décision commune des actionnaires.</p>
<p><strong>Est-ce qu’il y a des inconvénients?</strong> Évidemment que tout ne peut pas être parfaitement radieux sous le soleil! L’incorporation implique, comme vous le savez maintenant, le respect d’une structure et d’une hiérarchie pour le moins exigeante. Ce ne sont pas toutes les entreprises qui en bénéficient donc en parts égales.</p>
<p>De plus, il va sans dire que des frais accompagnent le démarrage d’une société par actions. Vous devrez payer des frais gouvernementaux d’incorporation, des frais d’administration beaucoup plus importants et des frais légaux parfois très lourds.</p>
<p>Cependant, l’incorporation est souvent un choix qui se force sur vous de par la taille que votre entreprise a atteinte. À un certain point, votre chiffre d’affaires et l’ampleur de vos activités deviendront trop importants pour être supporté par la structure d’une entreprise individuelle!</p>
<p><strong>C’est là que vous voudrez faire affaire avez un avocat invétéré dans le domaine de l’incorporation d’entreprise!</strong></p>
<h2><strong>Comment un avocat peut-il vous aider?</strong></h2>
<p>En vous conseillant à un moment crucial de la vie de votre entreprise! Nous l’avons dit et nous le répétons : le choix de structure d’une entreprise affecte énormément son fonctionnement. Ainsi, un avocat en droit des affaires vous conseillera sur des aspects de l’incorporation auxquels vous n’aviez même pas pensé!</p>
<p><StaticImage alt="engager-avocat-incorporation-services-juridiques-avantages" src="../images/engager-avocat-incorporation-services-juridiques-avantages.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Par exemple, une partie de la démarche d’incorporation consistera à décider de la <strong>taille de votre « capital-actions »</strong>.  Si cette dernière expression vous est complètement étrangère, vous voyez peut-être mieux pourquoi les services d’un avocat sont importants. Le capital-actions est tout simplement le nombre d’actions que votre compagnie émettra.</p>
<p>Aussi simple cela puisse-il être, il n’en demeure pas moins que si vous n’avez aucune connaissance du monde juridique ou des affaires, vous ne saurez pas quelle décision prendre.</p>
<p>Non seulement votre entreprise émettra-t-elle des actions, mais elle en émettra différents types qui confèreront différents droits énumérés dans une section précédents de ce même article. La <strong>création d’actions de différentes classes</strong> (ex : Type A, Type B, Type C, etc.) est un processus stratégique qui demande certaines connaissances que tous les gestionnaires d’entreprise ne possèdent pas.</p>
<p>Même <strong>le nom de l’entreprise</strong> n’est pas aussi simple à choisir qu’il n’y parait! Vous devez respecter de nombreuses contraintes, comme celles imposées par la Charte de la langue française, par la Loi 101 et par les lois entourant le droit d’auteur. Pour éviter d’enfreindre quelconque loi, vous devriez passer voir un avocat avec vote idée de nom pour qu’il vous donne le feu vert.</p>
<h2><strong>À quel prix obtiendrez-vous cette assistance légale?</strong></h2>
<p>Les avocats sont libres de fixer leur propre prix pour tous les types de services qu’ils offrent. Cependant, les standards du marché permettent tout de même de donner une bonne idée du prix que vous pouvez vous attendre à payer pour des services juridiques liés à l’incorporation d’une entreprise :</p>
<p><strong>Incorporation provinciale (Compagnie à numéro) : 800$-900$</strong></p>
<p><strong>Incorporation provinciale (Compagnie nommée) : 900-1000$</strong></p>
<p><strong>Livre de minutes provincial : Environ 100$</strong></p>
<p><strong>Incorporation fédérale (Compagnie à numéro) : 1100$ à 1200$</strong></p>
<p><strong>Incorporation fédérale (Compagnie nommée) : 1200$ à 1300$</strong></p>
<p>Mais pourquoi devrez-vous payer un tel prix alors que l’incorporation faite par vous-même coute légèrement au-dessus de 300$. Parce que ce dernier prix n’inclut pas les divers frais additionnels que vous devrez acquitter éventuellement, ce que l’avocat se charge de régler immédiatement.</p>
<p>De plus, l’avocat des affaires vous charge non seulement son expertise, mais vous paierez également pour ses conseils qui sont d’une valeur inestimable à la prospérité et longévité de votre compagnie.</p>
<p>Pour de tels prix, vous pouvez toutefois vous attendre à un service « clé en main » de la part de votre juriste. Vous n’aurez à vous soucier d’aucun aspect légal ou administratif de la démarche, cela est certain!</p>
<h2><strong>Faites affaire avec un avocat pour incorporer votre entreprise!</strong></h2>
<p>Ne mettez pas votre sécurité financière en péril pour quelques centaines de dollars. Il est certain que l’entreprise individuelle est une option plus simple que l’incorporation, mais elle présente tout de même d’importants risques au niveau de votre responsabilité personnelle. Vous ne sauverez certainement pas beaucoup d’argent si vous faites l’objet d’une poursuite de plusieurs milliers de dollars pour une faute que vous avez commise.</p>
<p>La même chose pourrait être dite des finances de l’entreprise! Vous n’avez pas à couler avec le navire juste parce que vous êtes à la tête de l’entreprise. Séparez-vous de ce fardeau avec l’incorporation!</p>
<p><StaticImage alt="tarif-avocat-incorporer-entreprise-compagnie-quebec-prix" src="../images/tarif-avocat-incorporer-entreprise-compagnie-quebec-prix.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Si vous êtes convaincu du bienfondé de l’incorporation et des bénéfices que celle-ci peut procurer à votre entreprise, vous avez cogné à la bonne porte pour être mis en contact avec les meilleurs experts du milieu!</p>
<p><strong>Tout ce que vous avez à faire, c’est de remplir le formulaire en bas de page et vous obtiendrez rapidement et gratuitement 3 soumissions d’avocats en droit des affaires!</strong></p>
<p><strong>Ceux-ci se feront un plaisir de vous assister dans votre processus d’incorporation, alors qu’attendez-vous?</strong></p>
    </SeoPage>
)
export default BlogPost50
  